import { useMemo } from "react";
import axios from "axios";

const useAPI = (token) => {
  const baseUrl = process.env.REACT_APP_API_URL;

  const httpRequest = useMemo(
    () =>
      axios.create({
        baseURL: baseUrl,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          JWT: token && token,
        },
      }),
    [baseUrl, token]
  );

  let api = httpRequest;

  return api;
};

export default useAPI;
