import React from "react";
import ReactDOM from "react-dom/client";
import "./theme/index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";

const root = ReactDOM.createRoot(document.getElementById("root"));

const providerConfig = {
  domain: process.env.REACT_APP_DOMAIN,
  clientId: process.env.REACT_APP_CLIENT_ID,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(process.env.REACT_APP_AUDIENCEe
      ? { audience: process.env.REACT_APP_AUDIENCEe }
      : null),
  },
};

datadogRum.init({
  applicationId: "cbae41be-abba-4af9-8d06-5958b743d581",
  clientToken: "pub11da3fb1b4f243a46ca0e2ba661ad430",
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "us5.datadoghq.com",
  service: "disbursements-app",
  env: process.env.ENV_NAME,
  // Specify a version number to identify the deployed version of your application in Datadog
  version: "1.0.0",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  startSessionReplayRecordingManually: true,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

root.render(
  <React.StrictMode>
    <Auth0Provider {...providerConfig}>
      <App />
    </Auth0Provider>
  </React.StrictMode>
);
