import React from "react";
import { ReactComponent as ArrowUp } from "../assets/ico_arrow_up.svg";
import { ReactComponent as ArrowDown } from "../assets/ico_arrow_down.svg";

const DataSorting = (sortConfig, setSortConfig) => {
  const sortableHeader = (type, title) => {
    return (
      <td
        className="hoverable"
        style={
          // align right if field is a number
          ["amount", "calculatedTotal", "numPayees", "index"].includes(type)
            ? { textAlign: "right", whiteSpace: "nowrap" }
            : { whiteSpace: "nowrap" }
        }
        onClick={() =>
          type === sortConfig.field
            ? setSortConfig({ field: type, ascending: !sortConfig.ascending })
            : setSortConfig({ field: type, ascending: true })
        }
      >
        {title}
        {sortConfig.field === type && (
          <>
            {sortConfig.ascending ? (
              <ArrowDown
                className="stroke_text_primary"
                style={{ scale: ".7", verticalAlign: "middle" }}
              />
            ) : (
              <ArrowUp
                className="stroke_text_primary"
                style={{ scale: ".7", verticalAlign: "middle" }}
              />
            )}
          </>
        )}
      </td>
    );
  };

  const sortData = (array) => {
    if (array.length) {
      array.sort((a, b) => {
        switch (true) {
          case sortConfig.ascending && isNaN(a[sortConfig.field]):
            return a[sortConfig.field].localeCompare(b[sortConfig.field]);
          case !sortConfig.ascending && isNaN(a[sortConfig.field]):
            return b[sortConfig.field].localeCompare(a[sortConfig.field]);
          case sortConfig.ascending && !isNaN(a[sortConfig.field]):
            return a[sortConfig.field] || a[sortConfig.field] === 0
              ? a[sortConfig.field] > b[sortConfig.field]
                ? 1
                : -1
              : 0;
          case !sortConfig.ascending && !isNaN(a[sortConfig.field]):
            return a[sortConfig.field] || a[sortConfig.field] === 0
              ? b[sortConfig.field] > a[sortConfig.field]
                ? 1
                : -1
              : 0;
          default:
            return true;
        }
      });
    }
  };

  return { sortableHeader, sortData };
};

export default DataSorting;
