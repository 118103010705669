import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  displayDollarAmount,
  monthDateYear,
  unCapitalize,
} from "../../utils/utils";
import useAPI from "../../api/api";
import { ROUTE_CONSTANTS } from "../../constants/routeConstants";
import { ReactComponent as ArrowLeft } from "../../assets/ico_arrow_left.svg";
import DataSorting from "../../utils/dataSorting";
import DataLoadingAndErrorHandling from "../../utils/dataLoadingAndErrorHandling";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const DisbursementDetail = ({
  theme,
  sortDisbursementDetailConfig,
  setSortDisbursementDetailConfig,
  highlightToDos,
}) => {
  const api = useAPI();
  const urlParams = useParams();
  const navigate = useNavigate();
  const { MONITOR } = ROUTE_CONSTANTS;
  const [showPlan, setShowPlan] = useState(false);
  const [disbursementPlan, setDisbursementPlan] = useState(undefined);
  const [disbursementPayeePlans, setDisbursementPayeePlans] = useState([]);
  const [disbursementPayeePlanStatuses, setDisbursementPayeePlanStatuses] =
    useState([]);

  useEffect(() => {
    highlightToDos();
  }, [showPlan, highlightToDos]);

  const { sortableHeader, sortData } = DataSorting(
    sortDisbursementDetailConfig,
    setSortDisbursementDetailConfig
  );

  const { renderErrorOrLoadingMessage, setFetchLoading, setFetchError } =
    DataLoadingAndErrorHandling();

  useEffect(() => {
    const getDisbursementAndPayeePlans = async () => {
      try {
        setFetchLoading(true);
        const response = await api.get(
          `/disbursement_plan/${urlParams.idString}`
        );
        setDisbursementPlan(response.data.disbursementPlan);
        setDisbursementPayeePlans(response.data.disbursementPlan.payeePlans);
      } catch (error) {
        setFetchError(true);
        console.error("get disbursement plan error", error);
      } finally {
        setFetchLoading(false);
      }
    };
    const getPlanStatuses = async () => {
      try {
        setFetchLoading(true);
        const response = await api.get(`/payments/${urlParams.idString}`);
        setDisbursementPayeePlanStatuses(response.data.payments);
      } catch (error) {
        setFetchError(true);
        console.error("get disbursement plan error", error);
      } finally {
        setFetchLoading(false);
      }
    };
    getDisbursementAndPayeePlans();
    getPlanStatuses();
  }, [api, urlParams, setFetchLoading, setFetchError]);

  disbursementPayeePlanStatuses?.length > 0
    ? disbursementPayeePlans.forEach((payeePlan) => {
        const match = disbursementPayeePlanStatuses.find(
          ({ payee_identifier }) => payee_identifier === payeePlan.payeeEmail
        );
        payeePlan.planStatus = match ? match.payment_status : "";
        payeePlan.payment_id = match ? match.payment_id : "";
      })
    : disbursementPayeePlans.forEach((payeePlan) => {
        payeePlan.planStatus = "Scheduled";
        payeePlan.payment_id = "";
      });

  const renderPlansTable = () => {
    sortData(disbursementPayeePlans);

    return (
      <table>
        <thead
          style={{
            height: 42,
          }}
        >
          <tr style={{ height: 64 }}>
            <td colSpan={6} style={{ fontSize: 18, fontWeight: 550 }}>
              Payments
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            style={{
              backgroundColor: theme.bg_tertiary,
              fontSize: 12,
              height: 42,
            }}
          >
            {sortableHeader("payeeName", "Name")}
            {sortableHeader("planStatus", "Payment Status")}
            {sortableHeader("payeeEmail", "Email Address")}
            {sortableHeader("amount", "Amount ($)")}
            {/* <td>Group</td> */}
            <td>Actions</td>
          </tr>
          {disbursementPayeePlans.length > 0 &&
            disbursementPayeePlans.map((payee, i) => {
              return (
                <tr
                  key={i}
                  className="border_top hoverable"
                  onClick={() => setShowPlan(payee)}
                >
                  <td>{payee.payeeName}</td>
                  <td>{payee.planStatus}</td>
                  <td>
                    {payee.payeeEmail?.length > 40
                      ? `${payee.payeeEmail.slice(0, 25)}...`
                      : payee.payeeEmail}
                  </td>
                  {
                    <td style={{ textAlign: "right" }}>
                      {payee.amount && displayDollarAmount(payee.amount)}
                    </td>
                  }
                  {/* <td>TODO</td> */}
                  {
                    <td
                      style={{
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                      }}
                    >
                      {/* {payee.planStatus === "Failed" ? (
                        <span style={{ color: theme.accent_color }}>
                          Resend Email
                        </span>
                      ) : (
                        <>
                          {payee.planStatus === "Pending" && (
                            <span style={{ color: theme.alert_color }}>
                              Cancel
                            </span>
                          )}
                        </>
                      )} */}
                    </td>
                  }
                </tr>
              );
            })}
          {/* remove below for v0 */}
          {/* <tr className="border_top">
            <td colSpan={3}>
              <span>TODO </span>
              <span>pagination</span>
            </td>
            <td colSpan={3} style={{ fontWeight: 900 }}>
              <div style={{ display: "flex", float: "right" }}>
                <button
                  style={{
                    backgroundColor: theme.bg_primary,
                    borderColor: theme.text_secondary,
                    fontWeight: 550,
                    marginRight: 10,
                  }}
                  onClick={() => console.log("Previous")}
                >
                  Previous
                </button>
                <button
                  style={{
                    backgroundColor: theme.bg_primary,
                    borderColor: theme.text_secondary,
                    fontWeight: 550,
                  }}
                  onClick={() => console.log("Next")}
                >
                  Next
                </button>
              </div>
            </td>
          </tr> */}
        </tbody>
      </table>
    );
  };

  const renderPlanDetail = () => {
    return (
      <>
        <button
          className="outline_icon hoverable"
          onClick={() => setShowPlan(false)}
        >
          <ArrowLeft className="stroke_text_primary" style={{ scale: ".8" }} />
        </button>
        <h2>Payment Plan Detail</h2>
        <hr></hr>
        <div className="detail_container">
          <div>
            <div className="detail_data">Recipient Name</div>
            <>{showPlan.payeeName}</>
          </div>
          <div>
            <div className="detail_data">Recipient Email</div>
            <>{showPlan.payeeEmail}</>
          </div>
          {/* remove below for v0 */}
          {/* <div>
            <div className="detail_data">Recipient Group</div>
            <span>TODO</span>
          </div> */}
          <div>
            <div className="detail_data">Recipient Status</div>
            <>{unCapitalize(showPlan.payeeStatus)}</>
          </div>
          <div>
            <div className="detail_data">Payment Amount</div>
            <>{showPlan.amount && displayDollarAmount(showPlan.amount)}</>
          </div>
          <div>
            <div className="detail_data">Payment Status</div>
            <>{showPlan.planStatus}</>
          </div>
          {showPlan.payment_id && (
            <div>
              <div className="detail_data">Payment ID</div>
              <>{showPlan.payment_id}</>
            </div>
          )}
        </div>
      </>
    );
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const csvExport = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + ".csv");
  };

  const exportPayeePlans = disbursementPayeePlans.map((plan) => {
    if (disbursementPayeePlanStatuses?.length > 0) {
      return {
        payment_id: plan.payment_id,
        payee: plan.payeeName,
        status: plan.planStatus,
        email: plan.payeeEmail,
        amount: plan.amount && displayDollarAmount(plan.amount),
      };
    } else
      return {
        payee: plan.payeeName,
        status: plan.planStatus,
        email: plan.payeeEmail,
        amount: plan.amount && displayDollarAmount(plan.amount),
      };
  });

  return (
    <div className="page_no_sidebar">
      <>
        {showPlan ? (
          <>{renderPlanDetail()}</>
        ) : (
          <>
            <button
              className="outline_icon hoverable"
              onClick={() => navigate(MONITOR)}
            >
              <ArrowLeft
                className="stroke_text_primary"
                style={{ scale: ".8" }}
              />
            </button>
            {disbursementPlan ? (
              <>
                <h2>{disbursementPlan.name}</h2>
                <div className="tile_container" style={{ marginTop: 20 }}>
                  <div className="tile">
                    <>Total Amount</>
                    <br></br>
                    {/* <span>TODO </span>
                    <span>Should both of these numbers come from backend?</span> */}
                    <h2>
                      {/* remove below for v0 */}
                      {/* {disbursementPayeePlans.length > 0 && (
                        <>
                          {displayDollarAmount(
                            disbursementPayeePlans
                              .filter(
                                ({ planStatus }) => planStatus === "Succeeded"
                              )
                              .reduce((runningTotal, payee) => {
                                if (payee.amount) {
                                  return (
                                    runningTotal + parseFloat(payee.amount)
                                  );
                                } else {
                                  return runningTotal;
                                }
                              }, 0)
                          ).slice(0, -3)}{" "}
                          of{" "}
                        </>
                      )} */}
                      {disbursementPlan?.calculatedTotal &&
                        displayDollarAmount(
                          disbursementPlan.calculatedTotal
                        ).slice(0, -3)}
                    </h2>
                  </div>
                  <div className="tile">
                    <>Total Payees</>
                    <br></br>
                    {/* <span>TODO </span>
                    <span>Should both of these numbers come from backend?</span> */}
                    <h2>
                      {/* remove below for v0 */}
                      {/* {
                        disbursementPayeePlans.filter(
                          ({ planStatus }) => planStatus === "Succeeded"
                        ).length
                      }{" "}
                      of  */}
                      {disbursementPlan.numPayees}
                    </h2>
                  </div>
                  <div className="tile">
                    <h3
                      style={{
                        display: "flex",
                        height: "100%",
                        margin: 0,
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div style={{ marginBottom: 10 }}>Creation Date:</div>
                        <div>Send Date:</div>
                        {/* remove below for v0 */}
                        {/* <div>Expiry Date:</div> */}
                      </div>
                      <div style={{ textAlign: "end" }}>
                        <div style={{ marginBottom: 10 }}>
                          {disbursementPlan?.createdAtLocalTimezone &&
                            monthDateYear(
                              new Date(
                                `${disbursementPlan?.createdAtLocalTimezone?.split("T")[0]}T00:00:00`
                              )
                            )}
                        </div>
                        <div style={{ height: 20 }}>
                          {disbursementPlan?.startDate &&
                            monthDateYear(
                              new Date(
                                `${disbursementPlan?.startDate?.split("T")[0]}T00:00:00`
                              )
                            )}
                        </div>
                        {/* remove below for v0 */}
                        {/* <div style={{ height: 20 }}>
                          {disbursementPlan?.endDate &&
                            monthDateYear(
                              new Date(
                                `${disbursementPlan?.endDate?.split("T")[0]}T00:00:00`
                              )
                            )}
                        </div> */}
                      </div>
                    </h3>
                  </div>
                  <div className="tile">
                    <>Created By</>
                    <h2>{disbursementPlan.createdByUserName}</h2>
                  </div>
                  {/* remove below for v0 */}
                  {/* <div className="tile">
                    Plan Status
                    <h2>
                      {disbursementPlan.status === "IN_PROCESS" ? (
                        <>Processing</>
                      ) : (
                        <>
                          {disbursementPlan.status === "APPROVED" ? (
                            <>Approved</>
                          ) : (
                            <>Draft</>
                          )}
                        </>
                      )}
                    </h2>
                  </div> */}
                </div>
                {disbursementPayeePlans.length > 0 ? (
                  <>
                    <button
                      style={{
                        backgroundColor: theme.accent_color,
                        borderColor: theme.accent_color,
                        color: theme.bg_tertiary,
                        float: "right",
                        marginBottom: 20,
                      }}
                      onClick={() =>
                        csvExport(
                          exportPayeePlans,
                          `${disbursementPlan.name}_plans`
                        )
                      }
                    >
                      Export CSV
                    </button>
                    {renderPlansTable()}
                  </>
                ) : (
                  <>
                    There was an issue fetching the payee data associated with
                    this disbursement.
                  </>
                )}
              </>
            ) : (
              <>
                {renderErrorOrLoadingMessage(
                  "Disbursement detail",
                  disbursementPlan
                )}
              </>
            )}
          </>
        )}
      </>
    </div>
  );
};
export default DisbursementDetail;
