import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useAPI from "../../api/api";
import { ROUTE_CONSTANTS } from "../../constants/routeConstants";
import { monthDateYear, displayDollarAmount } from "../../utils/utils";
import { ReactComponent as People } from "../../assets/ico_people.svg";
import { ReactComponent as DollarSign } from "../../assets/ico_dollar_sign.svg";
import { ReactComponent as Calendar } from "../../assets/ico_calendar.svg";
import { ReactComponent as List } from "../../assets/ico_list.svg";
import { ReactComponent as Squares } from "../../assets/ico_squares.svg";
import DataSorting from "../../utils/dataSorting";
import DataLoadingAndErrorHandling from "../../utils/dataLoadingAndErrorHandling";

const Monitor = ({
  theme,
  viewTiles,
  setViewTiles,
  filterDisbursements,
  setFilterDisbursements,
  sortDisbursementsConfig,
  setSortDisbursementsConfig,
}) => {
  const api = useAPI();
  const navigate = useNavigate();
  const { DISBURSEMENT_DETAIL } = ROUTE_CONSTANTS;
  const { sortableHeader, sortData } = DataSorting(
    sortDisbursementsConfig,
    setSortDisbursementsConfig
  );
  const [disbursementPlans, setDisbursementPlans] = useState([]);
  const [disbursementsToRender, setDisbursementsToRender] = useState([]);

  const { renderErrorOrLoadingMessage, setFetchLoading, setFetchError } =
    DataLoadingAndErrorHandling();

  useEffect(() => {
    const getDisbursementPlans = async () => {
      try {
        setFetchLoading(true);
        const response = await api.get(`/disbursement_plans`);
        response.data.disbursementPlans.forEach((plan) => {
          switch (plan.status) {
            case "DRAFT":
              return (plan.status = "Draft");
            case "IN_PROCESS":
              return (plan.status = "In Process");
            case "APPROVED":
              return (plan.status = "Approved");
            default:
              return "";
          }
        });
        setDisbursementPlans(response.data.disbursementPlans);
        setDisbursementsToRender(response.data.disbursementPlans);
      } catch (error) {
        setFetchError(true);
        console.error("get disbursement plans error", error);
      } finally {
        setFetchLoading(false);
      }
    };
    getDisbursementPlans();
  }, [api, setFetchLoading, setFetchError]);

  sortData(disbursementsToRender);

  const setHandleFilterBy = useCallback(
    (type) => {
      setFilterDisbursements(type);
      if (type) {
        setDisbursementsToRender(
          disbursementPlans.filter(({ status }) => status === type)
        );
      } else {
        setDisbursementsToRender(disbursementPlans);
      }
    },
    [disbursementPlans, setFilterDisbursements]
  );

  useEffect(() => {
    setHandleFilterBy(filterDisbursements);
  }, [filterDisbursements, setHandleFilterBy]);

  const renderFilterBy = (type) => {
    return (
      <div
        className={
          filterDisbursements === type ? "accent_box filter_by" : "filter_by"
        }
        style={filterDisbursements === type ? { margin: "-1px 2px" } : {}}
        onClick={() => setHandleFilterBy(type)}
      >
        {type}
        <span
          className="filter_by_box"
          style={
            filterDisbursements === type
              ? { borderColor: theme.accent_color }
              : {}
          }
        >
          {disbursementPlans.filter(({ status }) => status === type)?.length}
        </span>
      </div>
    );
  };

  const renderTile = (disbursement) => {
    return (
      <>
        <div>
          <h3 style={{ margin: "0 0 30px" }}>{disbursement.name}</h3>
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="circle_icon">
                <People className="fill_text_primary" />
              </div>
              <div style={{ marginLeft: 5 }}>
                <div style={{ color: theme.text_secondary }}>Total Payees</div>
                <div>{disbursement.numPayees}</div>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="circle_icon">
                <DollarSign className="stroke_text_primary" />
              </div>
              <div style={{ marginLeft: 5 }}>
                <div style={{ color: theme.text_secondary }}>Total Amount</div>
                <div>
                  {disbursement.calculatedTotal &&
                    displayDollarAmount(disbursement.calculatedTotal)}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="circle_icon">
                <Calendar className="fill_bg_tertiary stroke_text_primary" />
              </div>
              <div style={{ marginLeft: 5 }}>
                <div style={{ color: theme.text_secondary }}>Send Date</div>
                <div>
                  {disbursement.startDate &&
                    monthDateYear(
                      new Date(
                        `${disbursement.startDate?.split("T")[0]}T00:00:00`
                      )
                    )}
                </div>
              </div>
              {/* <div
                style={{
                  margin: "0 8px",
                  borderLeft: "solid",
                  borderColor: theme.text_secondary,
                  height: 30,
                }}
              ></div>
              <div>
                <div style={{ color: theme.text_secondary }}>Exp date</div>
                <div>
                  {disbursement.endDate &&
                    monthDateYear(
                      new Date(
                        `${disbursement.endDate?.split("T")[0]}T00:00:00`
                      )
                    )}
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* remove below for v0 */}
        {/* <div>
          <hr
            style={{
              margin: "20px -20px",
            }}
          ></hr>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              className="accent_box"
              style={{
                padding: 5,
                borderRadius: 5,
              }}
            >
              {disbursement.status}
            </div>
          </div>
        </div> */}
      </>
    );
  };

  const renderList = () => {
    return (
      <table>
        <thead
          style={{
            height: 42,
          }}
        >
          <tr style={{ height: 64 }}>
            <td colSpan={4} style={{ fontSize: 18, fontWeight: 550 }}>
              Disbursements
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            style={{
              backgroundColor: theme.bg_tertiary,
              fontSize: 12,
              height: 42,
            }}
          >
            {sortableHeader("name", "Payment Name")}
            {/* <td>Accepted Payees</td> */}
            {sortableHeader("numPayees", "Total Payees")}
            {/* <td>Accepted Amount</td> */}
            {sortableHeader("calculatedTotal", "Total Amount")}
            {/* {sortableHeader("status", "Status")} */}
            {sortableHeader("startDate", "Send Date")}
            {/* {sortableHeader("endDate", "Exp Date")} */}
          </tr>
          {disbursementsToRender.map((disbursement, i) => {
            return (
              <tr
                key={i}
                className="border_top hoverable"
                onClick={() =>
                  navigate(
                    `${DISBURSEMENT_DETAIL.replace(":idString", disbursement.idString)}`
                  )
                }
              >
                <td>{disbursement.name}</td>
                {/* <td>TODO</td> */}
                <td style={{ textAlign: "right" }}>{disbursement.numPayees}</td>
                {/* <td>TODO</td> */}
                <td style={{ textAlign: "right" }}>
                  {disbursement.calculatedTotal &&
                    displayDollarAmount(disbursement.calculatedTotal)}
                </td>
                {/* <td>{disbursement.status}</td> */}
                <td style={{ whiteSpace: "nowrap" }}>
                  {disbursement.startDate &&
                    monthDateYear(
                      new Date(
                        `${disbursement.startDate?.split("T")[0]}T00:00:00`
                      )
                    )}
                </td>
                {/* <td style={{ whiteSpace: "nowrap" }}>
                  {disbursement.endDate &&
                    monthDateYear(
                      new Date(
                        `${disbursement.endDate?.split("T")[0]}T00:00:00`
                      )
                    )}
                </td> */}
              </tr>
            );
          })}
          {/* remove below for v0 */}
          {/* <tr className="border_top">
            <td colSpan={2}>
              <span>TODO </span>
              <span>pagination</span>
            </td>
            <td colSpan={2} style={{ fontWeight: 900 }}>
              <div style={{ display: "flex", float: "right" }}>
                <button
                  style={{
                    backgroundColor: theme.bg_primary,
                    borderColor: theme.text_secondary,
                    fontWeight: 550,
                    marginRight: 10,
                  }}
                  onClick={() => console.log("Previous")}
                >
                  Previous
                </button>
                <button
                  style={{
                    backgroundColor: theme.bg_primary,
                    borderColor: theme.text_secondary,
                    fontWeight: 550,
                  }}
                  onClick={() => console.log("Next")}
                >
                  Next
                </button>
              </div>
            </td>
          </tr> */}
        </tbody>
      </table>
    );
  };

  return (
    <div className="page">
      <>
        <h2>Monitor</h2>
        <div
          style={{
            marginTop: 10,
            fontSize: 16,
            fontWeight: 300,
          }}
        >
          Monitor your payees
        </div>
        <hr></hr>
        {disbursementPlans?.length > 0 ? (
          <>
            <div
              style={{
                display: "flex",
                marginBottom: 30,
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  className={
                    !filterDisbursements ? "accent_box filter_by" : "filter_by"
                  }
                  style={!filterDisbursements ? { margin: "-1px 2px" } : {}}
                  onClick={() => setHandleFilterBy(null)}
                >
                  All
                  <span
                    className="filter_by_box"
                    style={
                      !filterDisbursements
                        ? { borderColor: theme.accent_color }
                        : {}
                    }
                  >
                    {disbursementPlans?.length}
                  </span>
                </div>
                {renderFilterBy("Draft")}
                {renderFilterBy("Approved")}
                {renderFilterBy("In Process")}
              </div>
              <div style={{ display: "flex" }}>
                <div
                  onClick={() => setViewTiles(true)}
                  className="adjacent_outline_icon left hoverable"
                  style={
                    viewTiles ? { backgroundColor: theme.bg_tertiary } : {}
                  }
                >
                  <Squares
                    className="fill_text_primary"
                    style={{ height: 18 }}
                  />
                </div>
                <div
                  onClick={() => setViewTiles(false)}
                  className="adjacent_outline_icon right hoverable"
                  style={
                    viewTiles ? {} : { backgroundColor: theme.bg_tertiary }
                  }
                >
                  <List className="fill_text_primary" style={{ height: 18 }} />
                </div>
              </div>
            </div>
            {disbursementsToRender?.length > 0 ? (
              <>
                {viewTiles ? (
                  <div className="tile_container">
                    {disbursementsToRender.map((disbursement, i) => {
                      return (
                        <div
                          className="tile hoverable"
                          style={{ fontSize: 12 }}
                          key={i}
                          onClick={() =>
                            navigate(
                              `${DISBURSEMENT_DETAIL.replace(":idString", disbursement.idString)}`
                            )
                          }
                        >
                          {renderTile(disbursement)}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <>{renderList()}</>
                )}
              </>
            ) : (
              <>
                There are no {filterDisbursements.toLowerCase()} disbursements
                to show.
              </>
            )}
          </>
        ) : (
          <>{renderErrorOrLoadingMessage("Disbursements", disbursementPlans)}</>
        )}
      </>
    </div>
  );
};
export default Monitor;
