const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const monthDateYear = (date) => {
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  let day = date.getDate();
  if (day < 10) {
    day = 0 + day.toLocaleString();
  }
  return month + " " + day + ", " + year;
};

export const displayDollarAmount = (value) => {
  return parseFloat(value).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const unCapitalize = (string) => {
  return string.charAt(0) + string.slice(1).toLowerCase();
};
