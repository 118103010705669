import { ReactComponent as ArrowLeft } from "../../assets/ico_arrow_left.svg";
import { ReactComponent as ArrowRight } from "../../assets/ico_arrow_right.svg";

const Footer = (
  theme,
  step,
  setStep,
  disableNextButton,
  clickNext,
  displayError,
  isInstantPay
) => {
  return (
    <>
      <div>
        <div>{displayError()}</div>
        {step < 5 && (
          <div
            style={{
              width: "100%",
              padding: "20px 0",
              borderTop: "solid",
              borderColor: theme.text_secondary,
              backgroundColor: theme.bg_secondary,
            }}
          >
            <div className="center_div">
              {step > 1 && (
                <button
                  style={{
                    backgroundColor: theme.bg_primary,
                    marginRight: 10,
                  }}
                  onClick={() => setStep(step - 1)}
                >
                  <ArrowLeft className="stroke_text_primary" />
                  <span
                    style={{
                      lineHeight: "24px",
                      marginLeft: 5,
                    }}
                  >
                    Back
                  </span>
                </button>
              )}
              <button
                disabled={disableNextButton()}
                style={
                  disableNextButton()
                    ? {
                        backgroundColor: theme.accent_color,
                        borderColor: theme.accent_color,
                        color: theme.bg_tertiary,
                        opacity: 0.2,
                      }
                    : {
                        backgroundColor: theme.accent_color,
                        borderColor: theme.accent_color,
                        color: theme.bg_tertiary,
                      }
                }
                onClick={() => clickNext()}
              >
                <span
                  style={{
                    lineHeight: "24px",
                    marginRight: 5,
                  }}
                >
                  {step === 4 ? (
                    <>
                      {isInstantPay.value ? (
                        <>Send Payments</>
                      ) : (
                        <>Schedule Payments</>
                      )}
                    </>
                  ) : (
                    <>Next Step</>
                  )}
                </span>
                <ArrowRight className="stroke_bg_primary" />
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Footer;
