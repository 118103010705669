import React, { useState, useMemo, useReducer, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
// import Switch from "react-switch";
import ProgressHeader from "./ProgressHeader";
import Footer from "./Footer";
import { ReactComponent as Cancel } from "../../assets/ico_cancel.svg";
import { ReactComponent as Upload } from "../../assets/ico_upload.svg";
import { ReactComponent as Eye } from "../../assets/ico_eye.svg";
import { ReactComponent as Add } from "../../assets/ico_add.svg";
import { SpinnerCircularFixed } from "spinners-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { displayDollarAmount } from "../../utils/utils";
import { ROUTE_CONSTANTS } from "../../constants/routeConstants";
import useAPI from "../../api/api";
import DataSorting from "../../utils/dataSorting";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import disbursements_upload_template from "../../assets/disbursements_upload_template.json";

const DisbursementsForm = ({ theme, dropDownStyle, dropDownTheme }) => {
  const api = useAPI();
  const navigate = useNavigate();
  const { DISBURSEMENT_DETAIL } = ROUTE_CONSTANTS;
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [step, setStep] = useState(1);
  const [paymentName, setPaymentName] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const [approvalNeeded, setApprovalNeeded] = useState(false);
  const [fileToAdd, setFileToAdd] = useState([]);
  const [showInvalidOnly, setShowInvalidOnly] = useState(false);
  const [disbursementPlanId, setDisbursementPlanId] = useState("");
  const [sortNewPlans, setSortNewPlans] = useState({
    field: "index",
    ascending: true,
  });
  const { sortableHeader, sortData } = DataSorting(
    sortNewPlans,
    setSortNewPlans
  );

  const isInstantPayOptions = useMemo(
    () => [
      {
        label: "Now",
        value: true,
      },
      {
        label: "Scheduled",
        value: false,
      },
    ],
    []
  );
  const recurrenceTypeOptions = useMemo(
    () => [
      {
        label: "Once",
        value: "ONCE",
      },
      {
        label: "Weekly",
        value: "WEEKLY",
      },
      {
        label: "Monthly",
        value: "MONTHLY",
      },
    ],
    []
  );

  const [isInstantPay, setIsInstantPay] = useState(isInstantPayOptions[0]);

  const [recurrenceType, setRecurrenceType] = useState(
    recurrenceTypeOptions[0]
  );

  const initialState = { dropDepth: 0, inDropZone: false, fileList: [] };

  const reducer = (state, action) => {
    switch (action.type) {
      case "SET_DROP_DEPTH":
        return { ...state, dropDepth: action.dropDepth };
      case "SET_IN_DROP_ZONE":
        return { ...state, inDropZone: action.inDropZone };
      case "ADD_FILE_TO_LIST":
        return { ...state, fileList: state.fileList.concat(action.files) };
      case "REMOVE_FILE_FROM_LIST":
        document.getElementById("file_upload").value = "";
        const newFiles = [...state.fileList];
        newFiles.splice(action["i"], 1);
        setFileToAdd([]);
        return { ...state, fileList: newFiles };
      default:
        return state;
    }
  };

  const [data, dispatch] = useReducer(reducer, initialState);

  const flashError = (message, time) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage(undefined);
    }, time);
  };

  const initialTotalAmount = fileToAdd
    .reduce((runningTotal, recipient) => {
      if (recipient.amount) {
        return runningTotal + parseFloat(recipient.amount);
      } else {
        return runningTotal;
      }
    }, 0)
    .toFixed(2);
  if (initialTotalAmount > 100000) {
    flashError(
      `The total amount to be disbursed is ${displayDollarAmount(initialTotalAmount)}. Maximum total is $100,000. Please edit the file and try again.`,
      10000
    );
    dispatch({ type: "REMOVE_FILE_FROM_LIST" });
  } else {
    fileToAdd.forEach((recipient, i) => {
      recipient.index = i + 1;
      recipient.name = recipient.name?.length
        ? recipient.name?.slice(0, 200)
        : "";
      try {
        recipient.amount = parseFloat(
          recipient.amount.toString().replace(/,/g, "")
        );
      } catch {
        recipient.amount = 0;
      }
      if (isNaN(recipient.amount)) {
        recipient.amount = 0;
      } else if (recipient.amount > 10000) {
        flashError(
          `${recipient.name} is set to receive ${displayDollarAmount(recipient.amount)}. Maximum amount for a recipient is $10,000. Please edit the file and try again.`,
          10000
        );
        dispatch({ type: "REMOVE_FILE_FROM_LIST" });
      }
    });
  }

  useEffect(() => {
    if (endDate && startDate > endDate) {
      flashError("End date cannot be before start date", 5000);
    }
  }, [startDate, endDate]);

  const displayError = () => {
    return (
      <>
        {errorMessage && (
          <div
            style={{
              color: theme.alert_color,
              backgroundColor: theme.bg_primary,
              textAlign: "center",
              fontWeight: 600,
              float: "bottom",
              paddingBottom: 20,
            }}
          >
            {errorMessage}
          </div>
        )}
      </>
    );
  };

  const handleSubmit = async () => {
    if (!(totalAmount > 100000)) {
      fileToAdd.forEach(({ amount }) => {
        amount = parseFloat(amount).toFixed(2);
      });
      setIsSubmitted(true);
      try {
        let disbursementPlan = {
          name: paymentName,
          claimFundsMessage: "Claim your money here:",
          isInstantPay: isInstantPay.value,
          recurrenceType: !isInstantPay.value
            ? recurrenceType.value
            : recurrenceTypeOptions[0].value,
          payorReminderEmailSettings: "TODO@changethis.com",
          status: "IN_PROCESS",
          payeePlans: fileToAdd,
          // calculated_total: totalAmount,
        };
        // TODO use below conditional for recurring
        // if (!isInstantPay.value) {
        disbursementPlan = {
          ...disbursementPlan,
          // startDate: startDate.toISOString().split("T")[0],
          // endDate:
          //   recurrenceType.value !== "ONCE" && endDate
          //     ? endDate.toISOString().split("T")[0]
          //     : startDate.toISOString().split("T")[0],
          // TODO use above code for startDate and endDate for recurring
          startDate: startDate
            ? startDate.toISOString().split("T")[0]
            : today.toISOString().split("T")[0],
          endDate: startDate
            ? startDate.toISOString().split("T")[0]
            : today.toISOString().split("T")[0],
        };
        // }
        const create_plan_response = await api.post(
          "/disbursement_plan",
          disbursementPlan
        );
        setDisbursementPlanId(
          create_plan_response.data.disbursementPlanIdString
        );
        const disbursementData = {
          disbursementPlanId:
            create_plan_response.data.disbursementPlanIdString,
          payeePlans: fileToAdd,
        };
        if (create_plan_response.data.disbursementPlanIdString) {
          await api.put("/approve_send_disbursement_plan", disbursementData);
        }
        setStep(step + 1);
      } catch (error) {
        console.error("Create disbursement plan error", error);
        if (error.response?.data?.message) {
          flashError(error.response.data.message, 5000);
        } else {
          flashError(
            "An error occurred while creating this disbursement plan.",
            5000
          );
        }
      } finally {
        setIsSubmitted(false);
      }
    }
  };

  const startOver = () => {
    setErrorMessage(undefined);
    setStep(1);
    setPaymentName();
    setIsSubmitted(false);
    setStartDate(null);
    setEndDate(null);
    setFileToAdd([]);
    setShowInvalidOnly(false);
    setDisbursementPlanId("");
    setIsInstantPay(isInstantPayOptions[0]);
    setRecurrenceType(recurrenceTypeOptions[0]);
    data.fileList = [];
  };

  const totalAmount = fileToAdd
    .reduce((runningTotal, recipient) => {
      if (recipient.amount) {
        return runningTotal + parseFloat(recipient.amount);
      } else {
        return runningTotal;
      }
    }, 0)
    .toFixed(2);

  useEffect(() => {
    if (totalAmount > 100000) {
      setErrorMessage(
        `The total amount to be disbursed is ${displayDollarAmount(totalAmount)}. Maximum total is $100,000.`
      );
    } else {
      setErrorMessage(undefined);
    }
  }, [totalAmount]);

  const handleFileUpload = (files) => {
    if (
      files[0].type === "text/csv" ||
      files[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      files[0].type === "application/x-iwork-numbers-sffnumbers"
    ) {
      dispatch({ type: "ADD_FILE_TO_LIST", files });
      const reader = new FileReader();
      reader.onload = (event) => {
        const workbook = XLSX.read(event.target.result, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(sheet);

        setFileToAdd(sheetData);
      };
      reader.readAsBinaryString(files[0]);
    }
  };

  const uploadSteps = () => {
    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      dispatch({ type: "SET_DROP_DEPTH", dropDepth: data.dropDepth + 1 });
    };
    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      dispatch({ type: "SET_DROP_DEPTH", dropDepth: data.dropDepth - 1 });
      if (data.dropDepth > 0) return;
      dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
    };
    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
      e.dataTransfer.dropEffect = "copy";
      dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: true });
    };
    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      let files = [...e.dataTransfer.files];
      if (files && files?.length > 0 && !fileToAdd?.length) {
        handleFileUpload(e.dataTransfer.files);
        e.dataTransfer.clearData();
        dispatch({ type: "SET_DROP_DEPTH", dropDepth: 0 });
        dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
      }
    };

    const recipientTable = () => {
      sortData(fileToAdd);

      const updateRecipient = ({ target: { id, value } }, recipient) => {
        const updatedData = fileToAdd.map((row) => {
          if (row.index === recipient.index) {
            return { ...row, [id]: value, new: true };
          }
          return row;
        });
        setFileToAdd(updatedData);
      };

      const removeRecipient = (recipientIndex) => {
        const recipientToRemove = fileToAdd.indexOf(
          fileToAdd.find(({ index }) => index === recipientIndex)
        );
        const newFile = [...fileToAdd];
        newFile.splice(recipientToRemove, 1);
        setFileToAdd(newFile);
      };

      const addRecipient = (e) => {
        e.preventDefault();
        const newRecipient = {
          index: fileToAdd?.length
            ? Math.max(...fileToAdd.map((obj) => obj.index)) + 1
            : 1,
          name: "",
          email: "",
          amount: "",
        };
        setFileToAdd([...fileToAdd, newRecipient]);
      };

      return (
        <div
          style={{
            width: 1000,
          }}
        >
          <h1 style={{ textAlign: "center" }}>
            {step === 3 ? <>Verify Data</> : <>Check the result</>}
          </h1>
          <table>
            <thead
              style={{
                height: 42,
              }}
            >
              <tr style={{ height: 64 }}>
                <td colSpan={step === 3 ? 3 : 4} style={{ fontSize: 18 }}>
                  Total Amount:{" "}
                  <span
                    style={{
                      fontSize: 20,
                      fontWeight: 600,
                    }}
                  >
                    {totalAmount && displayDollarAmount(totalAmount)}
                  </span>
                  {step === 3 && (
                    <span
                      className="accent_box"
                      style={{
                        fontSize: 12,
                        padding: 5,
                        marginLeft: 10,
                        borderRadius: 5,
                        verticalAlign: "text-top",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {fileToAdd?.length} rows found
                    </span>
                  )}
                </td>
                {step === 3 && (
                  <td colSpan={2}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="checkbox"
                        id="show_invalid_data_only"
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginRight: "-25%",
                          textAlign: "right",
                        }}
                        checked={showInvalidOnly}
                        onChange={() => setShowInvalidOnly(!showInvalidOnly)}
                      />
                      <label
                        htmlFor="show_invalid_data_only"
                        style={{ margin: "0", width: 200 }}
                      >
                        Show invalid data only
                      </label>
                    </div>
                  </td>
                )}
              </tr>
            </thead>
            <tbody>
              <tr
                style={{
                  backgroundColor: theme.bg_tertiary,
                  fontSize: 12,
                  height: 42,
                }}
              >
                {sortableHeader("index", "#")}
                {sortableHeader("name", "Name")}
                {sortableHeader("email", "Email address")}
                {sortableHeader("amount", "Amount ($)")}
                {step === 3 && <td>Actions</td>}
              </tr>
              {fileToAdd.map((recipient) => {
                return (
                  <tr
                    className={
                      showInvalidOnly &&
                      recipient.name !== "" &&
                      recipient.email !== "" &&
                      validEmailSyntax.test(recipient.email) &&
                      recipient.amount > 0
                        ? "hide"
                        : "border_top"
                    }
                    key={recipient.index}
                  >
                    <td style={{ textAlign: "right" }}>{recipient.index}</td>
                    <td
                      className={
                        step === 3 && (!recipient.name || recipient.new)
                          ? "invalid_data"
                          : ""
                      }
                    >
                      {step === 3 && (!recipient.name || recipient.new) ? (
                        <input
                          id="name"
                          onChange={(e) => updateRecipient(e, recipient)}
                          value={recipient.name}
                          className="invalid_data_input"
                          placeholder="+ Add name"
                        />
                      ) : (
                        recipient.name
                      )}
                    </td>
                    <td
                      className={
                        step === 3 &&
                        (!validEmailSyntax.test(recipient.email) ||
                          recipient.new)
                          ? "invalid_data"
                          : ""
                      }
                    >
                      {step === 3 &&
                      (!validEmailSyntax.test(recipient.email) ||
                        recipient.new) ? (
                        <input
                          id="email"
                          onChange={(e) => updateRecipient(e, recipient)}
                          className="invalid_data_input"
                          value={recipient.email}
                          placeholder="+ Add email"
                        />
                      ) : (
                        <>
                          {recipient.email?.length > 40
                            ? `${recipient.email.slice(0, 25)}...`
                            : recipient.email}
                        </>
                      )}
                    </td>
                    <td
                      className={
                        step === 3 && (!(recipient.amount > 0) || recipient.new)
                          ? "invalid_data"
                          : ""
                      }
                      style={{ textAlign: "right" }}
                    >
                      {step === 3 &&
                      (!(recipient.amount > 0) || recipient.new) ? (
                        <input
                          id="amount"
                          onChange={(e) => updateRecipient(e, recipient)}
                          value={recipient.amount}
                          type="number"
                          className="invalid_data_input"
                          style={{
                            textAlign: "right",
                          }}
                          placeholder="+ Add amount"
                          // step={0.01}
                        />
                      ) : (
                        recipient.amount &&
                        displayDollarAmount(recipient.amount)
                      )}
                    </td>
                    {step === 3 && (
                      <td
                        className="hoverable"
                        style={{
                          color: theme.accent_color,
                          fontWeight: 600,
                        }}
                        onClick={() => removeRecipient(recipient.index)}
                      >
                        Remove
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {step === 3 && (
            <div className="center_div" style={{ margin: "20px 0" }}>
              <button
                onClick={addRecipient}
                style={{
                  backgroundColor: theme.accent_color,
                  borderColor: theme.accent_color,
                  color: theme.bg_tertiary,
                  textAlign: "center",
                }}
              >
                Add row
              </button>
            </div>
          )}
        </div>
      );
    };

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const csvExport = (csvData, fileName) => {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + ".csv");
    };

    const exportData = disbursements_upload_template.payees;

    const exportCSV = (e) => {
      e.preventDefault();
      csvExport(exportData, "disbursements_upload_template");
    };

    return (
      <form
        noValidate={true}
        autoComplete="off"
        style={{ backgroundColor: theme.bg_primary }}
      >
        <div className="center_div" style={{ padding: 20 }}>
          {(() => {
            switch (step) {
              case 1:
                return (
                  <div style={{ width: 600 }}>
                    <h1 style={{ textAlign: "center" }}>
                      Provide general information
                    </h1>
                    <label htmlFor="payment_name">Payment name</label>
                    <input
                      id="payment_name"
                      onChange={(event) => setPaymentName(event.target.value)}
                      placeholder="Type payment name"
                      maxLength={500}
                      value={paymentName ?? ""}
                    />
                    <div style={{ fontWeight: 300, marginTop: 5 }}>
                      Set name for your new disbursement. Change at any time.
                    </div>
                    <label htmlFor="is_instant_pay">Payment type</label>
                    <Select
                      id="is_instant_pay"
                      options={isInstantPayOptions}
                      value={isInstantPay}
                      onChange={(option) => setIsInstantPay(option)}
                      classNamePrefix="dropdown"
                      // menuIsOpen={true} // use for styling dev
                      styles={dropDownStyle}
                      theme={dropDownTheme}
                    />
                    {!isInstantPay.value && (
                      <>
                        <div style={{ fontWeight: 300, marginTop: 5 }}>
                          Payments will be sent at 10:00AM CST on scheduled
                          dates.
                        </div>
                        <label htmlFor="start_date">Schedule date</label>
                        <DatePicker
                          id="start_date"
                          className="date_picker_input"
                          placeholderText="Select scheduled date"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          minDate={today}
                          maxDate={dateOneYearFromYesterday}
                          popperPlacement="top"
                        />
                        {/* Hide recurrence for v0 */}
                        {/* <label htmlFor="recurrence_type">Recurrence</label>
                        <Select
                          id="recurrence_type"
                          options={recurrenceTypeOptions}
                          value={recurrenceType}
                          onChange={(option) => setRecurrenceType(option)}
                          classNamePrefix="dropdown"
                          // menuIsOpen={true} // use for styling dev
                          styles={dropDownStyle}
                          theme={dropDownTheme}
                        />
                        {recurrenceType.value !== "ONCE" && (
                          <>
                            <label htmlFor="end_date">Expiry date</label>
                            <DatePicker
                              id="end_date"
                              className="date_picker_input"
                              placeholderText="Select scheduled date"
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              minDate={startDate ? startDate : today}
                              maxDate={dateOneYearFromYesterday}
                              popperPlacement="top"
                            />
                          </>
                        )} */}
                      </>
                    )}
                    {/* Hide approval for v0 */}
                    {/* <hr></hr>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <Switch
                        onChange={() => setApprovalNeeded(!approvalNeeded)}
                        checked={approvalNeeded}
                        height={24}
                        width={41}
                        onColor={theme.accent_color}
                        offColor={theme.text_secondary}
                        id="approval_needed"
                        checkedIcon={false}
                        uncheckedIcon={false}
                      />
                      <div style={{ margin: "5px 12px" }}>
                        <label
                          style={{ margin: "0" }}
                          htmlFor="approval_needed"
                        >
                          Need team approval?
                        </label>
                        <div style={{ fontWeight: 300, marginTop: 5 }}>
                          Add people's approval you want to get.
                        </div>
                      </div>
                    </div>
                    <label htmlFor="approval_from">Approval users</label>
                    <Select
                      id="approval_from"
                      options={recurrenceTypeOptions}
                      value={recurrenceType}
                      onChange={(option) => setRecurrenceType(option)}
                      classNamePrefix="dropdown"
                      // menuIsOpen={true} // use for styling dev
                      styles={dropDownStyle}
                      theme={dropDownTheme}
                      isMulti
                    /> */}
                  </div>
                );
              case 2:
                return (
                  <div style={{ width: 600 }}>
                    <h1 style={{ textAlign: "center" }}>
                      Upload CSV / XLSX file
                    </h1>
                    <div
                      onDrop={(e) => handleDrop(e)}
                      onDragOver={(e) => handleDragOver(e)}
                      onDragEnter={(e) => handleDragEnter(e)}
                      onDragLeave={(e) => handleDragLeave(e)}
                    >
                      <input
                        type="file"
                        accept=".csv, .xlsx, .numbers"
                        onChange={(e) => handleFileUpload(e.target.files)}
                        id="file_upload"
                        style={{
                          display: "none",
                        }}
                        disabled={fileToAdd?.length}
                      />
                      <label
                        htmlFor="file_upload"
                        className={
                          fileToAdd?.length
                            ? "upload_box"
                            : "upload_box hoverable"
                        }
                        style={
                          fileToAdd?.length
                            ? { borderColor: theme.text_secondary }
                            : { borderColor: theme.accent_color }
                        }
                      >
                        <div
                          className="progress_icon"
                          style={{ transform: "scale(.8)" }}
                        >
                          <Upload className="stroke_text_primary" />
                        </div>
                        <div>
                          <b
                            style={
                              fileToAdd?.length
                                ? { color: theme.text_secondary }
                                : { color: theme.accent_color }
                            }
                          >
                            Click to upload{" "}
                          </b>
                          <span>or drag and drop</span>
                          <div style={{ marginTop: 10, fontSize: 12 }}>
                            (Or manually add recipients on the next page)
                          </div>
                        </div>
                      </label>
                      {data.fileList.map((file, i) => (
                        <div
                          key={i}
                          style={{ textAlign: "center", marginTop: 10 }}
                        >
                          {file[0]?.name}
                          <Cancel
                            onClick={() =>
                              dispatch({ type: "REMOVE_FILE_FROM_LIST" })
                            }
                            className="remove_csv hoverable"
                          />
                        </div>
                      ))}
                      {!fileToAdd?.length && (
                        <div className="center_items" style={{ marginTop: 20 }}>
                          <p>
                            File formatting is strict, download the template for
                            an example of the required format.
                          </p>
                          <button
                            style={{
                              backgroundColor: theme.accent_color,
                              borderColor: theme.accent_color,
                              color: theme.bg_tertiary,
                            }}
                            onClick={(e) => exportCSV(e)}
                          >
                            Download Template
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                );
              case 3:
              case 4:
                return recipientTable();
              case 5:
                return (
                  <div
                    className="center_page"
                    style={{
                      flexDirection: "column",
                      backgroundColor: theme.accent_color,
                      height: "100vh",
                      margin: -20,
                      color: theme.bg_primary,
                    }}
                  >
                    <h1 style={{ textAlign: "center" }}>Success!</h1>
                    <div
                      style={{
                        color: theme.text_secondary,
                        marginBottom: 50,
                      }}
                    >
                      Payment was created
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: 220,
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="center_items"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(
                            `${DISBURSEMENT_DETAIL.replace(":idString", disbursementPlanId)}`
                          )
                        }
                      >
                        <div className="icon_button">
                          <Eye className="stroke_accent_color" />
                        </div>
                        <p>View on report</p>
                      </div>
                      <div
                        className="center_items"
                        style={{ cursor: "pointer" }}
                        onClick={() => startOver()}
                      >
                        <div
                          className="icon_button"
                          style={{ textAlign: "top" }}
                        >
                          <Add className="fill_accent_color" />
                        </div>
                        <p>Create new</p>
                      </div>
                    </div>
                  </div>
                );
              default:
                return null;
            }
          })()}
        </div>
      </form>
    );
  };

  const today = new Date();
  let oneYearFromYesterday = new Date();
  oneYearFromYesterday.setDate(oneYearFromYesterday.getDate() + 365);
  const dateOneYearFromYesterday = oneYearFromYesterday
    ?.toISOString()
    ?.split("T")[0];

  const validEmailSyntax =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const disableNextButton = () => {
    switch (step) {
      case 1:
        return (
          !paymentName ||
          (!isInstantPay.value &&
            (!startDate ||
              (recurrenceType.value !== "ONCE" &&
                (!endDate || startDate > endDate))))
        );
      case 3:
        return (
          fileToAdd.find((recipient) => {
            return (
              !recipient.name || // any invalid names
              !validEmailSyntax.test(recipient.email) || // any invalid emails
              !(recipient.amount > 0) // any non positive amounts
            );
          }) ||
          totalAmount > 100000 || // total over $100,000
          !fileToAdd?.length // no recipients
        );
      default:
        return false;
    }
  };

  const clickNext = () => {
    switch (step) {
      case 3:
        setStep(step + 1);
        return setShowInvalidOnly(false);
      case 4:
        handleSubmit();
        return;
      default:
        setStep(step + 1);
        return;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh",
        width: "100vw",
        backgroundColor: theme.bg_primary,
      }}
    >
      {isSubmitted && (
        <>
          <div className="overlay"></div>
          <div className="modal center_page">
            <div>
              <SpinnerCircularFixed
                color={theme.accent_color}
                secondaryColor={theme.text_secondary}
                speed={150}
                size={300}
                thickness={40}
              />
              <h3>Uploading data...</h3>
              <p style={{ color: theme.text_secondary }}>
                Do not close the browser window
              </p>
            </div>
          </div>
        </>
      )}
      <div>
        {ProgressHeader(theme, step)}
        {uploadSteps()}
      </div>
      {Footer(
        theme,
        step,
        setStep,
        disableNextButton,
        clickNext,
        displayError,
        isInstantPay
      )}
    </div>
  );
};

export default DisbursementsForm;
