import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const NP_blue = "#1d3aab";

  return (
    <div
      className="center_page"
      style={{
        backgroundColor: "rgba(0,0,0,.1)",
        flexDirection: "column",
      }}
    >
      <div
        className="center_div"
        style={{
          flexDirection: "column",
          justifyContent: "space-around",
          backgroundColor: "white",
          height: 250,
          maxWidth: 400,
          width: "95%",
          borderRadius: 10,
        }}
      >
        <h3
          className="center_div"
          style={{
            backgroundColor: NP_blue,
            color: "white",
            width: "95%",
            height: 60,
            borderRadius: 4,
            marginTop: -50,
          }}
        >
          Disbursements
        </h3>
        <button
          style={{
            backgroundColor: NP_blue,
            color: "white",
            width: "90%",
            borderRadius: 4,
            display: "block",
          }}
          onClick={() => loginWithRedirect()}
        >
          Login
        </button>
        <img
          style={{ width: 140 }}
          src="np-logo-blue.png"
          alt="neural_payments_logo"
        />
      </div>
    </div>
  );
};

export default Login;
