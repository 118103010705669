import React, { useState } from "react";

const DataLoadingAndErrorHandling = () => {
  const [fetchLoading, setFetchLoading] = useState(true);
  const [fetchError, setFetchError] = useState(false);

  const renderErrorOrLoadingMessage = (type, data) => {
    return (
      <>
        {fetchLoading ? (
          // || (!fetchError && !data.length)
          <>{type} data is loading.</>
        ) : (
          <>
            {fetchError ? (
              <>There was an issue fetching {type.toLowerCase()} data.</>
            ) : (
              <>There are no {type.toLowerCase()} to show.</>
            )}
          </>
        )}
      </>
    );
  };

  return { renderErrorOrLoadingMessage, setFetchLoading, setFetchError };
};

export default DataLoadingAndErrorHandling;
