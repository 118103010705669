const Theme = () => {
  // get colors from stylesheet
  const theme = {
    text_primary: getComputedStyle(document.body).getPropertyValue(
      "--text_primary"
    ),
    text_secondary: getComputedStyle(document.body).getPropertyValue(
      "--text_secondary"
    ),
    bg_primary: getComputedStyle(document.body).getPropertyValue(
      "--bg_primary"
    ),
    bg_secondary: getComputedStyle(document.body).getPropertyValue(
      "--bg_secondary"
    ),
    bg_tertiary: getComputedStyle(document.body).getPropertyValue(
      "--bg_tertiary"
    ),
    accent_color: getComputedStyle(document.body).getPropertyValue(
      "--accent_color"
    ),
    alert_color: getComputedStyle(document.body).getPropertyValue(
      "--alert_color"
    ),
    info_color: getComputedStyle(document.body).getPropertyValue(
      "--info_color"
    ),
  };

  const dropDownStyle = {
    control: (base) => ({
      ...base,
      backgroundColor: theme.bg_primary,
      height: 44,
      borderColor: theme.text_secondary,
      padding: "0 6px",
    }),
    menuList: (base) => ({
      ...base,
      borderRadius: 8,
      backgroundColor: theme.bg_primary,
    }),
    option: (base) => ({
      ...base,
      padding: 15,
      "&:hover": {
        backgroundColor: theme.bg_tertiary,
        // color: theme.bg_primary,
        fontWeight: 600,
        cursor: "pointer",
      },
    }),
    multiValue: (base) => ({
      ...base,
      border: "solid",
      borderWidth: 1,
      borderColor: theme.text_secondary,
    }),
    // multiValueLabel: (base) => ({
    //   ...base,
    //   color: theme.alert_color,
    // }),
    clearIndicator: (base) => ({
      ...base,
      color: theme.text_primary,
      ":hover": {
        backgroundColor: theme.bg_primary,
        color: theme.alert_color,
        transform: "scale(1.15)",
        cursor: "pointer",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      ":hover": {
        transform: "scale(1.15)",
        cursor: "pointer",
      },
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: theme.text_secondary,
      ":hover": {
        backgroundColor: theme.alert_color,
        color: theme.bg_primary,
        cursor: "pointer",
      },
    }),
  };

  const dropDownTheme = (base) => ({
    ...base,
    colors: {
      primary: theme.bg_tertiary,
    },
    borderRadius: 8,
  });

  return { theme, dropDownStyle, dropDownTheme };
};

export default Theme;
